import React, { useMemo } from 'react';
import styles from './YuccaPlant.Popup.module.scss';
import viewports from '../../globalStyles/_Viewports.module.scss';
import YuccaPlantPopupPart1 from './Parts/Part1/YuccaPlant.Popup.Part1';
import YuccaPlantPopupPart2 from './Parts/Part2/YuccaPlant.Popup.Part2';
import { useYuccaPlantPopupContext } from './YuccaPlant.Popup.Context';

const YuccaPlantPopup = () => {
  const { currentPart } = useYuccaPlantPopupContext();

  let popupContent = <YuccaPlantPopupPart1 />;

  if (currentPart === 2) {
    popupContent = <YuccaPlantPopupPart2 />;
  }

  return useMemo(
    () => (
      <div className={styles.yuccaPlantPopup}>
        <img
          srcSet={`
            /popup_assets/yucca_plant/YuccaPlantBackground_540_343.png ${viewports.viewportSmallNum}w,
            /popup_assets/yucca_plant/YuccaPlantBackground_1080_686.png ${viewports.viewportLargeNum}w,
            /popup_assets/yucca_plant/YuccaPlantBackground_2160_1372.png ${viewports.viewport2XLargeNum}w
          `}
          src="/popup_assets/yucca_plant/YuccaPlantBackground_540_343.png"
          alt="Yucca Plant Background"
        />
        <div className={styles.contentArea}>{popupContent}</div>
      </div>
    ),
    [currentPart]
  );
};

export default YuccaPlantPopup;
