import React, { createContext, ReactNode, useContext, useMemo, useState } from 'react';
import { SidebarContextProps } from './SidebarContext.types';

const SidebarContext = createContext<Partial<SidebarContextProps>>({});

const SidebarContextProvider = ({ children }: { children: ReactNode }) => {
  const [isOpen, setIsOpen] = useState(false);

  const value: SidebarContextProps = useMemo(() => {
    return {
      isOpen,
      setIsOpen,
    };
  }, [isOpen]);

  return <SidebarContext.Provider value={value}>{children}</SidebarContext.Provider>;
};

export const useSidebarContext = () => useContext(SidebarContext) as SidebarContextProps;
export { SidebarContext };
export default SidebarContextProvider;
