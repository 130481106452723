import { ControlElement, VerticalLayout } from '@jsonforms/core/src/models/uischema';

const yuccaPlantSidebarSchemaUI: VerticalLayout = {
  type: 'VerticalLayout',
  elements: [
    {
      type: 'Control',
      scope: '#/properties/headline',
    } as ControlElement,
    {
      type: 'Control',
      scope: '#/properties/description',
      options: {
        multi: true,
      },
    } as ControlElement,
    {
      type: 'Control',
      scope: '#/properties/callToActionButtonText',
    } as ControlElement,
    {
      type: 'Control',
      scope: '#/properties/successMessage',
    } as ControlElement,
  ],
};

export default yuccaPlantSidebarSchemaUI;
