import { vanillaStyles } from '@jsonforms/vanilla-renderers';
import styles from './YuccaPlant.Sidebar.module.scss';

const sidebarSchemaStylesYuccaPlant = {
  styles: [
    ...vanillaStyles,
    {
      name: 'vertical.layout.item',
      classNames: [styles.verticalLayoutItem],
    },
    {
      name: 'control.label',
      classNames: [styles.controlLabel],
    },
    {
      name: 'control.input',
      classNames: [styles.controlInput],
    },
    {
      name: 'control.validation',
      classNames: [styles.controlValidation],
    },
    {
      name: 'control.validation.error',
      classNames: [styles.controlValidationError],
    },
  ],
};

export default sidebarSchemaStylesYuccaPlant;
