import React, { useMemo } from 'react';
import classNames from 'classnames';
import type { PopupDisplayAreaProps } from './PopupDisplayArea.types';
import styles from './PopupDisplayArea.module.scss';
import YuccaPlantPopup from '../../../popups/YuccaPlant/YuccaPlant.Popup';
import { useSidebarContext } from '../../../contexes/SidebarContext/SidebarContext';

const PopupDisplayArea = ({ className, ...props }: PopupDisplayAreaProps) => {
  const { isOpen } = useSidebarContext();

  return useMemo(
    () => (
      <main className={classNames(styles.popupDisplayArea, { [styles.opened]: isOpen }, className)} {...props}>
        <YuccaPlantPopup />
      </main>
    ),
    [isOpen]
  );
};

export default PopupDisplayArea;
