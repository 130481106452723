import React from 'react';
import classNames from 'classnames';
import HeadingProps from './Heading.types';
import styles from './Heading.module.scss';

const Heading = ({ className, children, ...props }: HeadingProps) => {
  return (
    <h3 className={classNames(styles.heading, className)} {...props}>
      {children}
    </h3>
  );
};

export default Heading;
