import { JsonSchema7 } from '@jsonforms/core';

const yuccaPlantSidebarSchema: JsonSchema7 = {
  type: 'object',
  title: 'General Settings',
  properties: {
    headline: {
      title: 'Headline',
      type: 'string',
      minLength: 1,
    },
    description: {
      title: 'Description',
      type: 'string',
      minLength: 1,
    },
    callToActionButtonText: {
      title: 'Call To Action Button Text',
      type: 'string',
      minLength: 1,
    },
    successMessage: {
      title: 'Success Message',
      type: 'string',
      minLength: 1,
    },
  },
  dependencies: {},
  required: ['headline', 'description', 'callToActionButtonText', 'successMessage'],
};

export default yuccaPlantSidebarSchema;
