import { FontFamily } from '@centrual/popupsmart_api_client';

const getFontWeights = (fontFamily: FontFamily) => {
  return fontFamily.variants.map(variant => {
    if (variant === 'italic') {
      return 'ital';
    }

    return variant.replace('italic', 'i');
  });
};

export default getFontWeights;
