import React from 'react';
import classNames from 'classnames';
import type { SidebarToggleButtonProps } from './SidebarToggleButton.types';
import styles from './SidebarToggleButton.module.scss';
import ChevronDown from '../../../assets/svg/ChevronDown';

const SidebarToggleButton = ({ className, opened, ...props }: SidebarToggleButtonProps) => {
  const localOpened = opened ?? false;

  return (
    <button type="button" className={classNames(styles.button, { [styles.opened]: localOpened }, className)} {...props}>
      <ChevronDown className={styles.chevronIcon} />
    </button>
  );
};

export default SidebarToggleButton;
