import { ControlElement, VerticalLayout } from '@jsonforms/core/src/models/uischema';

const yuccaPlantSidebarSchemaUI: VerticalLayout = {
  type: 'VerticalLayout',
  elements: [
    {
      type: 'Control',
      scope: '#/properties/name',
    } as ControlElement,
    {
      type: 'Control',
      scope: '#/properties/email',
    } as ControlElement,
    {
      type: 'Control',
      scope: '#/properties/fontFamilySelection',
    } as ControlElement,
  ],
};

export default yuccaPlantSidebarSchemaUI;
