import { PublicApiFactory } from '@centrual/popupsmart_api_client';
import axios from 'axios';
import Config from '../Config';

const axiosInstance = axios.create({ baseURL: Config.api.baseUrl });

const apiClient = PublicApiFactory(undefined, Config.api.baseUrl, axiosInstance);

export { apiClient, axiosInstance };
export default undefined;
