import { JsonSchema7 } from '@jsonforms/core';

const yuccaPlantPopupPart1Schema: JsonSchema7 = {
  type: 'object',
  properties: {
    name: {
      title: 'Your name',
      type: 'string',
    },
    email: {
      title: 'Email',
      type: 'string',
      format: 'email',
    },
    fontFamilySelection: {
      title: 'Select Font',
      type: 'string',
      enum: [],
    },
  },
  dependencies: {},
  required: ['name', 'email'],
};

export default yuccaPlantPopupPart1Schema;
