import React, { useMemo, useState } from 'react';
import classNames from 'classnames';
import { useMediaQuery } from 'usehooks-ts';
import { JsonForms } from '@jsonforms/react';
import { JsonFormsCore } from '@jsonforms/core';
import { JsonFormsStyleContext, vanillaCells, vanillaRenderers } from '@jsonforms/vanilla-renderers';
import { ErrorObject } from 'ajv';
import type { SidebarProps } from './Sidebar.types';
import styles from './Sidebar.module.scss';
import { useSidebarContext } from '../../../contexes/SidebarContext/SidebarContext';
import viewports from '../../../globalStyles/_Viewports.module.scss';
import SidebarToggleButton from '../../general/SidebarToggleButton/SidebarToggleButton';
import yuccaPlantSidebarSchema from '../../../popups/YuccaPlant/YuccaPlant.Sidebar.Schema';
import yuccaPlantSidebarSchemaUI from '../../../popups/YuccaPlant/YuccaPlant.Sidebar.Schema.UI';
import yuccaPlantSidebarSchemaConfig from '../../../popups/YuccaPlant/YuccaPlant.Sidebar.Schema.Config';
import yuccaPlantSidebarSchemaStyles from '../../../popups/YuccaPlant/YuccaPlant.Sidebar.Schema.Styles';
import { useYuccaPlantSidebarContext } from '../../../popups/YuccaPlant/YuccaPlant.Sidebar.Context';
import Heading from '../../general/Heading/Heading';

const Sidebar = (props: SidebarProps) => {
  const { isOpen, setIsOpen } = useSidebarContext();
  const { popupEditorData, setPopupEditorData } = useYuccaPlantSidebarContext();
  const [popupEditorErrors, setPopupEditorErrors] = useState<ErrorObject[]>([]);
  const isInMobileViewport = useMediaQuery(`(max-width: calc(${viewports.viewportLarge} - 1px ))`);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  const setPopupEditorDataHandler = ({ data, errors }: Pick<JsonFormsCore, 'data' | 'errors'>) => {
    if (!errors) {
      setPopupEditorErrors([]);
    } else {
      setPopupEditorErrors(errors);
    }

    setPopupEditorData(data);
  };

  const additionalErrors = popupEditorErrors.map((error: ErrorObject) => {
    const err = Object.create(error);

    switch (error.keyword) {
      case 'required':
        err.message = 'This field is required';
        break;
      default:
        break;
    }

    return err;
  });

  return useMemo(() => {
    return (
      <aside className={classNames(styles.sidebar, { [styles.open]: isOpen })} {...props}>
        {isInMobileViewport && <SidebarToggleButton opened={isOpen} className={styles.toggleButton} onClick={toggleSidebar} />}

        <div className={styles.sidebarScrollableArea}>
          <JsonFormsStyleContext.Provider value={yuccaPlantSidebarSchemaStyles}>
            <Heading className={styles.heading}>General Settings</Heading>

            <JsonForms
              schema={yuccaPlantSidebarSchema}
              uischema={yuccaPlantSidebarSchemaUI}
              data={popupEditorData}
              validationMode="ValidateAndHide"
              renderers={vanillaRenderers}
              cells={vanillaCells}
              onChange={setPopupEditorDataHandler}
              config={yuccaPlantSidebarSchemaConfig}
              additionalErrors={additionalErrors}
            />
          </JsonFormsStyleContext.Provider>
        </div>
      </aside>
    );
  }, [popupEditorData, isOpen, isInMobileViewport]);
};

export default Sidebar;
