import React, { createContext, ReactNode, useContext, useEffect, useMemo, useState } from 'react';
import { useLocalStorage } from 'usehooks-ts';
import YuccaPlantPopupContextProps, { YuccaPlantPopupContentData } from './YuccaPlant.Popup.Context.Types';

const YuccaPlantPopupContext = createContext<Partial<YuccaPlantPopupContextProps>>({});

const YuccaPlantPopupContextProvider = ({ children }: { children: ReactNode }) => {
  const initialLocalStorageValues: YuccaPlantPopupContentData = {
    email: '',
    fontFamilySelection: '',
    yourName: '',
  };

  const [localStoragePopupContent, setLocalStoragePopupContent] = useLocalStorage<YuccaPlantPopupContentData>('yuccaPlantPopupContentData', initialLocalStorageValues);
  const [popupContentData, setPopupContentData] = useState(localStoragePopupContent);
  const [currentPart, setCurrentPart] = useState(1);

  useEffect(() => {
    setLocalStoragePopupContent(popupContentData);
  }, [popupContentData]);

  const value: YuccaPlantPopupContextProps = useMemo(() => {
    return {
      popupContentData,
      setPopupContentData,
      currentPart,
      setCurrentPart,
    };
  }, [popupContentData, currentPart]);

  return <YuccaPlantPopupContext.Provider value={value}>{children}</YuccaPlantPopupContext.Provider>;
};

export const useYuccaPlantPopupContext = () => useContext(YuccaPlantPopupContext) as YuccaPlantPopupContextProps;
export { YuccaPlantPopupContext };
export default YuccaPlantPopupContextProvider;
