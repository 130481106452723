import React, { createContext, ReactNode, useContext, useEffect, useMemo, useState } from 'react';
import { useLocalStorage } from 'usehooks-ts';
import YuccaPlantSidebarContextProps, { YuccaPlantData } from './YuccaPlant.Sidebar.Context.Types';

const YuccaPlantSidebarContext = createContext<Partial<YuccaPlantSidebarContextProps>>({});

const YuccaPlantSidebarContextProvider = ({ children }: { children: ReactNode }) => {
  const initialLocalStorageValues: YuccaPlantData = {
    headline: '',
    description: '',
    callToActionButtonText: '',
    successMessage: '',
  };
  const [localStoragePopupEditorData, setLocalStoragePopupEditorData] = useLocalStorage<YuccaPlantData>('popupEditorData', initialLocalStorageValues);
  const [popupEditorData, setPopupEditorData] = useState(localStoragePopupEditorData);

  useEffect(() => {
    setLocalStoragePopupEditorData(popupEditorData);
  }, [popupEditorData]);

  const value: YuccaPlantSidebarContextProps = useMemo(() => {
    return {
      popupEditorData,
      setPopupEditorData,
    };
  }, [popupEditorData]);

  return <YuccaPlantSidebarContext.Provider value={value}>{children}</YuccaPlantSidebarContext.Provider>;
};

export const useYuccaPlantSidebarContext = () => useContext(YuccaPlantSidebarContext) as YuccaPlantSidebarContextProps;
export { YuccaPlantSidebarContext };
export default YuccaPlantSidebarContextProvider;
