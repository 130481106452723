import React from 'react';
import classNames from 'classnames';
import type { CircleIconButtonProps } from './CircleIconButton.types';
import styles from './CircleIconButton.module.scss';
import FontIcon from '../../general/FontIcon/FontIcon';
import colors from '../../../globalStyles/_Colors.module.scss';

const CircleIconButton = ({ iconProps, onClick, className }: CircleIconButtonProps) => {
  const onClickHandler = () => {
    onClick?.();
  };

  return (
    <button type="button" className={classNames(styles.circleIconButton, className)} onClick={onClickHandler}>
      <FontIcon size={13} color={colors.colorCircleIconButtonIconColor} {...iconProps} />
    </button>
  );
};

export default CircleIconButton;
