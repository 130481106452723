import React from 'react';
import styles from './MainApp.module.scss';
import Sidebar from '../../screenParts/Sidebar/Sidebar';
import PopupDisplayArea from '../../screenParts/PopupDisplayArea/PopupDisplayArea';
import SidebarContextProvider from '../../../contexes/SidebarContext/SidebarContext';
import YuccaPlantSidebarContextProvider from '../../../popups/YuccaPlant/YuccaPlant.Sidebar.Context';
import YuccaPlantPopupContextProvider from '../../../popups/YuccaPlant/YuccaPlant.Popup.Context';

const MainApp = () => {
  return (
    <SidebarContextProvider>
      <YuccaPlantSidebarContextProvider>
        <YuccaPlantPopupContextProvider>
          <section className={styles.mainApp}>
            <Sidebar />
            <PopupDisplayArea />
          </section>
        </YuccaPlantPopupContextProvider>
      </YuccaPlantSidebarContextProvider>
    </SidebarContextProvider>
  );
};

export default MainApp;
