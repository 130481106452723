import React, { useEffect, useMemo, useState } from 'react';
import { FontFamily } from '@centrual/popupsmart_api_client';
import { JsonForms } from '@jsonforms/react';
import { JsonFormsStyleContext, vanillaCells, vanillaRenderers } from '@jsonforms/vanilla-renderers';
import { ErrorObject } from 'ajv';
import { JsonFormsCore } from '@jsonforms/core';
import GoogleFontLoader from 'react-google-font-loader';
import { RotatingSquare } from 'react-loader-spinner';
import { apiClient } from '../../../../helpers/api';
import { useYuccaPlantPopupContext } from '../../YuccaPlant.Popup.Context';
import yuccaPlantPopupPart1Schema from './YuccaPlant.Popup.Part1.Schema';
import { useYuccaPlantSidebarContext } from '../../YuccaPlant.Sidebar.Context';
import yuccaPlantPopupPart1SchemaUI from './YuccaPlant.Popup.Part1.Schema.UI';
import yuccaPlantPopupPart1SchemaConfig from './YuccaPlant.Popup.Part1.Schema.Config';
import CircleIconButton from '../../../../components/interactive/CircleIconButton/CircleIconButton';
import yuccaPLantPopupPart1SchemaStyles from './YuccaPlant.Popup.Part1.Schema.Styles';
import styles from './YuccaPlant.Popup.Part1.module.scss';
import Button from '../../../../components/interactive/Button/Button';
import getFontWeights from '../../../../helpers/getFontWeights';
import fonts from '../../../../globalStyles/_Fonts.module.scss';
import colors from '../../../../globalStyles/_Colors.module.scss';

const YuccaPlantPopupPart1 = () => {
  const { setCurrentPart, setPopupContentData, popupContentData } = useYuccaPlantPopupContext();
  const { popupEditorData } = useYuccaPlantSidebarContext();
  const [areFontFamiliesLoading, setAreFontFamiliesLoading] = useState(true);
  const [areFontFamiliesLoadedSuccessfully, setAreFontFamiliesLoadedSuccessfully] = useState(false);
  const [fontFamilies, setFontFamilies] = useState<FontFamily[]>([]);
  const [selectedFontData, setSelectedFontData] = useState<FontFamily | null>(null);
  const [jsonSchema, setJsonSchema] = useState(yuccaPlantPopupPart1Schema);
  const [popupErrors, setPopupErrors] = useState<ErrorObject[]>([]);

  const loadFontFamilies = async () => {
    setAreFontFamiliesLoading(true);
    setFontFamilies([]);

    try {
      const response = await apiClient.getGooglefont();
      setAreFontFamiliesLoadedSuccessfully(true);

      const fontFamiliesExceptMonospace = response.data.filter(fontFamily => fontFamily.category !== 'monospace');
      const changedJsonSchema = {
        ...jsonSchema,
        properties: {
          ...jsonSchema.properties,
          fontFamilySelection: {
            ...jsonSchema.properties!.fontFamilySelection,
            enum: fontFamiliesExceptMonospace.map(fontFamily => fontFamily.family),
          },
        },
      };
      setJsonSchema(changedJsonSchema);
      setFontFamilies(fontFamiliesExceptMonospace);
    } catch (e) {
      console.log(e);
      setAreFontFamiliesLoadedSuccessfully(false);
    }

    setAreFontFamiliesLoading(false);
  };

  const loadFont = async (fontName: string) => {
    const selectedFont = fontFamilies.find(fontFamily => fontFamily.family === fontName);
    if (selectedFont) {
      setSelectedFontData(selectedFont);
    } else {
      setSelectedFontData(null);
    }
  };

  useEffect(() => {
    loadFontFamilies();
  }, []);

  useEffect(() => {
    if (!areFontFamiliesLoading && areFontFamiliesLoadedSuccessfully) {
      loadFont(popupContentData.fontFamilySelection);
    }
  }, [areFontFamiliesLoading, areFontFamiliesLoadedSuccessfully]);

  const getLoadingMessage = () => {
    return (
      <div className={styles.loadingArea}>
        <RotatingSquare height="100" width="100" color={colors.colorLoadingAnimationDefault} ariaLabel="rotating-square-loading" strokeWidth="4" visible />
        <p className={styles.loadingParagraph}>Loading font families...</p>
      </div>
    );
  };

  const renderLoadingFailed = () => {
    return (
      <div>
        <p>Loading font families failed.</p>
        <button
          type="button"
          onClick={() => {
            loadFontFamilies();
          }}
        >
          Try again
        </button>
      </div>
    );
  };

  const setPopupDataHandler = async ({ data, errors }: Pick<JsonFormsCore, 'data' | 'errors'>) => {
    if (!errors) {
      setPopupErrors([]);
    } else {
      setPopupErrors(errors);
    }

    const oldFontFamily = popupContentData.fontFamilySelection;
    setPopupContentData(data);

    if (oldFontFamily !== data.fontFamilySelection) {
      await loadFont(data.fontFamilySelection);
    }
  };

  const handleButtonClick = () => {
    setCurrentPart(2);
  };

  return useMemo(() => {
    if (areFontFamiliesLoading) {
      return getLoadingMessage();
    }

    if (!areFontFamiliesLoadedSuccessfully) {
      return renderLoadingFailed();
    }

    const additionalErrors = popupErrors.map((error: ErrorObject) => {
      const err = Object.create(error);

      switch (error.keyword) {
        case 'required':
          err.message = 'This field is required';
          break;
        default:
          break;
      }

      return err;
    });

    let renderGoogleFontLoader = null;

    if (selectedFontData) {
      renderGoogleFontLoader = (
        <GoogleFontLoader
          fonts={[
            {
              font: selectedFontData.family,
              weights: getFontWeights(selectedFontData),
            },
          ]}
          subsets={selectedFontData.subsets}
          display="swap"
        />
      );
    }

    return (
      <div className={styles.container} style={{ fontFamily: popupContentData.fontFamilySelection || fonts.fontDefault }}>
        {renderGoogleFontLoader}

        <CircleIconButton iconProps={{ icon: 'close' }} className={styles.circleButton} />
        <h3 className={styles.heading}>{popupEditorData.headline || 'Headline'}</h3>
        <p className={styles.description}>{popupEditorData.description || 'Description'}</p>

        <JsonFormsStyleContext.Provider value={yuccaPLantPopupPart1SchemaStyles}>
          <JsonForms
            schema={jsonSchema}
            uischema={yuccaPlantPopupPart1SchemaUI}
            data={popupContentData}
            validationMode="ValidateAndHide"
            renderers={vanillaRenderers}
            cells={vanillaCells}
            onChange={setPopupDataHandler}
            config={yuccaPlantPopupPart1SchemaConfig}
            additionalErrors={additionalErrors}
          />
        </JsonFormsStyleContext.Provider>

        <Button className={styles.button} onClick={handleButtonClick}>
          {popupEditorData.callToActionButtonText || 'Call to action button'}
        </Button>
      </div>
    );
  }, [areFontFamiliesLoading, popupEditorData, popupContentData, popupErrors]);
};

export default YuccaPlantPopupPart1;
