import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import MainApp from './components/applications/MainApp/MainApp';
import './globalStyles/Global.scss';
import './globalStyles/PopupSmartIcons.scss';

const element = document.querySelector('#root');
const root = ReactDOM.createRoot(element!);

root.render(
  <React.StrictMode>
    <MainApp />
  </React.StrictMode>
);

// If you want to start measuring performance in your MainApp, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
