import React from 'react';
import classNames from 'classnames';
import ButtonProps from './Button.types';
import styles from './Button.module.scss';

const Button = ({ children, onClick, className }: ButtonProps) => {
  const onClickHandler = () => {
    onClick?.();
  };

  return (
    <button type="button" className={classNames(styles.button, className)} onClick={onClickHandler}>
      {children}
    </button>
  );
};

export default Button;
