import { vanillaStyles } from '@jsonforms/vanilla-renderers';
import styles from './YuccaPlant.Popup.Part1.module.scss';

const yuccaPLantPopupPart1SchemaStyles = {
  styles: [
    ...vanillaStyles,
    {
      name: 'vertical.layout',
      classNames: [styles.verticalLayout],
    },
    {
      name: 'vertical.layout.item',
      classNames: [styles.verticalLayoutItem],
    },
    {
      name: 'control.label',
      classNames: [styles.controlLabel],
    },
    {
      name: 'control.input',
      classNames: [styles.controlInput],
    },
    {
      name: 'control.select',
      classNames: [styles.controlInput],
    },
    {
      name: 'control.validation',
      classNames: [styles.controlValidation],
    },
    {
      name: 'control.validation.error',
      classNames: [styles.controlValidationError],
    },
  ],
};

export default yuccaPLantPopupPart1SchemaStyles;
