import * as React from 'react';
import type { GeneratedSvgProps } from '../../declarations/GeneratedSvgProps';

const SvgChevronDown = ({ size, color, ...props }: GeneratedSvgProps) => {
  const localColor = color ?? 'currentColor';
  const localSize = size ?? '1em';
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={localSize} height={localSize} {...props}>
      <path
        d="M21 11.109c0-1.329-1.481-2.122-2.587-1.385L12 14 5.587 9.725C4.481 8.988 3 9.78 3 11.109c0 .556.278 1.076.741 1.385l7.15 4.766a2 2 0 0 0 2.219 0l7.15-4.766c.462-.309.74-.828.74-1.385z"
        fill={localColor}
      />
    </svg>
  );
};

export default SvgChevronDown;
