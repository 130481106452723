const env = process.env.NODE_ENV || 'development';

const Config = {
  nodeEnv: env,
  api: {
    baseUrl: 'https://apiv2.popupsmart.com/api',
  },
};

export default Config;
