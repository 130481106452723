import React from 'react';
import Lottie from 'lottie-react';
import successAnimation from '../../../../assets/lottie/success_custom.json';
import { useYuccaPlantSidebarContext } from '../../YuccaPlant.Sidebar.Context';
import styles from './YuccaPlant.Popup.Part2.module.scss';

const YuccaPlantPopupPart2 = () => {
  const { popupEditorData } = useYuccaPlantSidebarContext();

  return (
    <div className={styles.container}>
      <Lottie className={styles.animation} animationData={successAnimation} loop={false} />
      <h5 className={styles.successMessage}>{popupEditorData.successMessage || 'Success!'}</h5>
    </div>
  );
};

export default YuccaPlantPopupPart2;
